.delete-modal-title {
  font-size: 1.25rem;
  font-weight: 800;
  color: #ff4742;
  line-height: 1.5rem;
  margin: 0;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.25rem;
  padding: 0.5rem 0;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: -webkit-fill-available;
  align-items: center;
  gap: 1rem;
}

.yes-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 6rem;

  background: #ff4742;
  border: 1px solid #ff4742;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
}

.yes-button:hover,
.yes-button:active {
  background-color: initial;
  background-position: 0 0;
  color: #ff4742;
}

.yes-button:active {
  opacity: 0.5;
}

.no-button {
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 6rem;

  align-items: center;
  border: 1px solid #ff4742;
  background: #ffffff;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  color: #ff4742;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
}

.bold-text {
  font-weight: bold;
  font-size: 1.15rem;
}
