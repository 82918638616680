.add-button {
  display: flex;
  align-items: center;
  padding: 0.35rem;
  border-radius: 50%;
  background-color: #f4f4f4;
  border: none;
}

.add-button-icon {
  height: 1.25rem;
  width: 1.25rem;
  object-fit: contain;
}
