.add-room-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 2.5rem 0 1rem 0;
}

.add-room-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: #f4f4f4;
  border: none;
  border-radius: 0.25rem;
  width: 94%;
}
