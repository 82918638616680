.batch-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.batch-select-container {
}

.batch-name-id-container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  font-size: 0.925rem;
  font-weight: 400;
}

.mapped-rooms-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.25rem;
}

.batch-name-id-container input {
  width: 90%;
  padding: 0.45rem;
  border-radius: 0.25rem;
}

input {
}

.save-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  width: 94%;
  padding: 0.6rem 0.5rem;
  border-radius: 0.25rem;
  border: none;
}
