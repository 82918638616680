.header-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.header-add-room-container {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}

.user-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.user-image {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}
