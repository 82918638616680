.batch-card-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border: 2px solid #e0e0e0;
  padding: 1rem;
  border-radius: 0.25rem;
  width: -webkit-fill-available;
}

.batches {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  align-items: center;
}

.batch {
  display: flex;
  align-items: center;
  gap: 0.35rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: #f4f4f4;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
}

.add-batch-button {
  display: flex;
  align-items: center;
  padding: 0.35rem;
  border-radius: 50%;
  background-color: #f4f4f4;
  border: none;
}
